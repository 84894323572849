<template>
    <div v-if="show" class="fixed z-30 inset-0 bg-black/30 backdrop-blur-md flex justify-center items-center md:p-12 hover:cursor-pointer" @click.self="closePopup">
      <div class="bg-slate-950/80 w-full h-full md:p-6 p-5 rounded-lg shadow-lg shadow-slate-900/70 relative max-h-full overflow-auto cursor-default" @click.stop>
        <slot class="backdrop-blur-xl"></slot> <!-- Slot for custom content -->
      </div>
    </div>
  </template>
  
<script lang="ts">
  import { defineComponent, onMounted, onUnmounted, watch, toRefs } from 'vue';
  
  export default defineComponent({
    name: 'PopupWindow',
    props: {
      show: Boolean
    },
    emits: ['update:show'],
    setup(props, { emit }) {
      const { show } = toRefs(props);
  
      const handlePopState = () => {
        if (show.value) {
          emit('update:show', false);
        }
      };
  
      const openPopup = () => {
        window.history.pushState({ popupOpened: true }, '');
        emit('update:show', true);
      };
  
      const closePopup = () => {
        if (window.history.state?.popupOpened) {
          window.history.back(); // Triggers popstate and closes the popup
        } else {
          emit('update:show', false);
        }
      };
  
      watch(show, (newValue) => {
        if (newValue) {
          openPopup();
        }
      });
  
      onMounted(() => {
        window.addEventListener('popstate', handlePopState);
      });
  
      onUnmounted(() => {
        window.removeEventListener('popstate', handlePopState);
      });
  
      return { closePopup };
    }
  });
  </script>
  