import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "fixed inset-0 bg-slate-900" }
const _hoisted_2 = { class: "absolute h-fit inset-0 bg-gradient-to-r from-blue-950/90 via-slate-800 to-blue-850/90 backdrop-blur-xl" }
const _hoisted_3 = { class: "relative min-h-screen flex flex-col items-center justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.circles, (circle) => {
        return (_openBlock(), _createElementBlock("div", {
          key: circle.id,
          class: _normalizeClass(circle.classes),
          style: _normalizeStyle(circle.style)
        }, null, 6))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}