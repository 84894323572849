<template>


  <AnimatedBackground class="overflow-auto">

    <TitleHeader />

    <div class="w-px bg-slate-300/60 mx-auto md:py-7 py-6"></div>

    <NavigationBar />

    <div class="w-px bg-slate-300/60 mx-auto md:py-6 py-5"></div>

    <div class="flex flex-row my-4 space-x-4">
            <SocialLink v-for="(link, index) in socialLinks" :key="index" :link="link" class="rounded-full p-2.5 outline outline-1 outline-slate-50/20" />
    </div>

    <div class="w-px bg-slate-300/60 mx-auto md:py-6 py-5"></div>

    <div class="md:w-3/5 w-4/5 flex flex-col items-center justify-center px-4 pb-4">

      <div class="relative w-full aspect-video">
        <iframe width="100%" height="100%" class="rounded-md shadow-sm shadow-slate-400/20" src="https://www.youtube.com/embed/bRLj1MaURiI?si=-g1s-C-M6SVt_PnQ" title="Sound Design Reel 2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <div class="w-px bg-slate-300/60 mx-auto md:py-6 py-4"></div>

      <a href="https://youtu.be/GsEmHT5KfXU" target="_blank">
        <img class="rounded-md shadow-sm shadow-slate-400/20" src="https://johnjaniganmills.ca/images/photos/slider32.png"/>
      </a>

      <div class="w-px bg-slate-300/60 mx-auto md:py-6 py-4"></div>

      <a href="https://youtu.be/Cks2tNkB5OY" target="_blank">
      <img class="rounded-md shadow-sm shadow-slate-400/20" src="https://johnjaniganmills.ca/images/photos/boneNbass.jpg"/>
      </a>

      <div class="w-px bg-slate-300/60 mx-auto md:py-6 py-4"></div>

      <a href="https://youtu.be/1G9zWZyjHqA" target="_blank">
        <img class="rounded-md shadow-sm shadow-slate-400/20" src="https://johnjaniganmills.ca/images/photos/slider4.jpg"/>
      </a>

      <div class="w-px bg-slate-300/60 mx-auto md:py-6 py-4"></div>

      <a href="https://youtu.be/saTiIlirTyo" target="_blank">
        <img class="rounded-md shadow-sm shadow-slate-400/20" src="https://johnjaniganmills.ca/images/photos/slider5.jpg"/>
      </a>
      
    </div>

    <footer class="bg-slate-950/80 w-full text-slate-50 font-thin text-center py-3">
      <h>created by john janigan-mills using typescript, vue.js, and tailwindcss<br></h>
      <h>(c) 2024</h>
    </footer>

  </AnimatedBackground>


    <!-- Add other page components here -->
</template>

<script lang="ts">
import AnimatedBackground from './components/AnimatedBackground.vue';
import TitleHeader from './components/TitleHeader.vue';
import NavigationBar from './components/NavigationBar.vue';
import SocialLink from './components/SocialLink.vue';

export default {
  name: 'App',
  components: {
    AnimatedBackground,
    TitleHeader,
    NavigationBar,
    SocialLink
  },
  setup() {
      const socialLinks = ['https://github.com/cosmicmonkeysounds',
                           'https://jananigans.itch.io/',
                           'https://jananigans.bandcamp.com/',
                           'https://soundcloud.com/johnjaniganmills',
                           'https://www.instagram.com/jananigans__',
                           'https://ca.linkedin.com/in/john-janigan-mills-a9362b86']
    return {socialLinks};
  },
}
</script>




<style>
@import './styles_in.css';
</style>