<template>
  <button :class="['btn flex flex-col items-center justify-center', additionalClasses]" @click="onClick">
    <template v-if="$slots.default">
      <slot></slot> <!-- Slot for inner HTML content -->
    </template>
    <template v-else>
      {{ label }} <!-- Display label if no slot content -->
    </template>
  </button>
</template>

<script lang="ts">
export default {
  name: 'CustomButton',
  props: {
    onClick: {
      type: Function,
      default: () => {}
    },
    additionalClasses: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
