<template>
  <PopupWindow :show="show" @update:show="updateShow">

    <!-- Top bar -->
    <div class="sticky bg-slate-950/95 -top-5 md:-top-6 py-2 pointer-events-auto rounded-lg shadow-lg pl-2">

      <button class="absolute right-3 text-2xl text-slate-50 shadow-lg btn-circle outline outline-red-400/80 mr-3 mt-1 hover:bg-slate-300/15" @click.stop="closePopup">
        <h2 class="font-mono">X</h2>
      </button>

      <h2 class="text-4xl font-semibold mb-4 pt-2 text-slate-50 font-mono shadow-sm">about:</h2>
      
    </div>

    <!-- Body -->
    <div class="text-left md:pb-4 pb-4 md:px-4 px-1 md:ml-0 ml-1">
      <p class="text-xl text-slate-50 font-thin pt-1">
        John Janigan-Mills is a bassist, multi-instrumentalist, composer, producer, sound designer, programmer, game dev, and human currently based in K’jipuktuk/Halifax, Nova Scotia. <br><br>
        If there are sounds being made, harmonious or abstract, John is likely to be involved. After spending his high school years studying with Nova Scotia jazz legend Skip Beckwith, he earned a scholarship and studied Jazz Composition and Electronic Music Production at Berklee College of Music in 2014, entering as a sophomore and completing four semesters. During this time, he studied bass, composition, and musicianship with veteran musicians like Gary Willis, Victor Wooten, Whit Browne, Susan Hagen, Eddie Gomez, Michael League, and Liam Wilson. <br><br>
        After leaving Berklee in 2016, John set off on a journey to explore the rich intersections of engineering and music. Following his interest in electrical engineering, he became infatuated with the flexibility and accessibility of software, sparking years of study in audio, game, and systems programming, while working in music production and performance in the studio and stage.<br><br>
        John has a wide range of experience; from composing and producing charming, quirky indie film soundtracks with a single microphone, to creating interactive systems and sounds for games and multi-media projects. He smoothly pivots from recording solo artists and ensembles, to playing various rhythm section instruments across a wide range of genres and eras.
      </p>
    </div>

  </PopupWindow>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import PopupWindow from './PopupWindow.vue';

export default defineComponent({
  name: 'AboutPopup',
  components: {
    PopupWindow
  },
  props: {
    show: Boolean as PropType<boolean>
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const updateShow = (newShow: boolean) => {
      emit('update:show', newShow);
    };

    const closePopup = () => {
      if (window.history.state?.popupOpened) {
        window.history.back(); // Triggers popstate and closes the popup
      } else {
        emit('update:show', false);
      }
    };

    return { updateShow, closePopup };
  }
});
</script>
