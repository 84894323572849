<template>
    <PopupWindow :show="show" @update:show="updateShow">
  
      <!-- Top bar -->
      <div class="sticky bg-slate-950/95 -top-5 md:-top-6 py-2 pointer-events-auto rounded-lg shadow-lg pl-2">
  
        <button class="absolute right-3 text-2xl text-slate-50 shadow-lg btn-circle outline outline-red-400/80 mr-3 mt-1 hover:bg-slate-300/15" @click.stop="closePopup">
          <h2 class="font-mono">X</h2>
        </button>
  
        <h2 class="text-4xl font-semibold mb-4 pt-2 text-slate-50 font-mono shadow-sm">contact: (eastcoastjohnbass at gmail dot com)</h2>
        
      </div>
  
      <div>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label class="block text-slate-50 text-sm font-bold mb-2" for="name">
          name
        </label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Your Name" v-model="form.name">
      </div>
      <div class="mb-4">
        <label class="block text-slate-50 text-sm font-bold mb-2" for="email">
          email
        </label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Your Email" v-model="form.email">
      </div>
      <div class="mb-4">
        <label class="block text-slate-50 text-sm font-bold mb-2" for="message">
          message
        </label>
        <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" placeholder="Your Message" v-model="form.message"></textarea>
      </div>
      <!-- <VueReCaptcha class="h-full w-full" @verify="onCaptchaVerified" :sitekey="yourSiteKey" ref="recaptcha"></VueReCaptcha> -->
      <button v-if="captchaPassed" type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        submit
      </button>
    </form>
  </div>
  
    </PopupWindow>
  </template>
  
  <script lang="ts">
  import { defineComponent, PropType, ref, reactive } from 'vue';
  import PopupWindow from './PopupWindow.vue';
//   import { VueReCaptcha } from 'vue-recaptcha-v3';
  
  export default defineComponent({

    name: 'ContactPopup',

    components: {
      PopupWindow,
    //   VueReCaptcha
    },

    props: {
      show: Boolean as PropType<boolean>
    },

    emits: ['update:show'],

    setup(props, { emit }) {



      const updateShow = (newShow: boolean) => {
        emit('update:show', newShow);
      };
  
      const closePopup = () => {
        if (window.history.state?.popupOpened) {
          window.history.back(); // Triggers popstate and closes the popup
        } else {
          emit('update:show', false);
        }
      };

    const form = reactive({
      name: '',
      email: '',
      message: ''
    });

    const captchaPassed = ref(true);

    const onCaptchaVerified = (response: string) => {
        
      captchaPassed.value = response.length > 0;
    };

    const handleSubmit = async () => 
    {
        if (! captchaPassed.value) 
        {
            alert('Please complete the reCAPTCHA challenge.');
            return;
        }

        try 
        {
            const response = await fetch('your-server-endpoint.php', 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(form),
            });

            if (response.ok) 
            {
                const data = await response.json();
                console.log('Server response:', data);
                // Handle success - maybe clear the form or display a success message
            } 
            
            else 
            {
                console.error('Server error:', response.statusText);
                // Handle server errors (e.g., display error message)
            }
        } 
        
        catch (error) 
        {
            console.error('Request failed:', error);
            // Handle network errors (e.g., display error message)
        }
    };

      const yourSiteKey = "6Lc2Qk8pAAAAAJzeJaMy_hGNwC2OO125O33T-Mgd";
  
      return { updateShow, closePopup, form, captchaPassed, onCaptchaVerified, handleSubmit, yourSiteKey };
    },


  });
  </script>

<!-- site key:   6Lc2Qk8pAAAAAJzeJaMy_hGNwC2OO125O33T-Mgd -->
<!-- secret key: 6Lc2Qk8pAAAAABPBClJcs4XLQMC6RnjWrScxgXQw -->