<template>
  <PopupWindow :show="show" @update:show="updateShow">

    <!-- Sticky Top Bar -->
    <div class="sticky -top-6 bg-slate-950/95 z-10 py-2 pointer-events-auto rounded-lg shadow-lg pl-2 group flex flex-col">

      <div class="flex flex-col sm:flex-row items-center justify-between w-full">
      <!-- Portfolio Title and Close Button on the same row for small screens -->
      <div class="flex justify-between items-center w-full sm:w-auto mb-2 sm:mb-0">
          <!-- Portfolio Title -->
          <h2 class="text-4xl font-semibold mb-4 pt-2 text-slate-50 font-mono shadow-sm">portfolio:</h2>

          <!-- Close Button for small screens -->
          <button class="text-2xl text-slate-50 shadow-lg btn-circle outline outline-red-400/80 mr-3 hover:bg-slate-300/15 sm:hidden" @click.stop="closePopup">
              <h2 class="font-mono">X</h2>
          </button>
      </div>

      <!-- Medium Filter Buttons -->
      <div class="flex flex-row flex-wrap items-center justify-start space-x-4 text-slate-50 w-full sm:w-auto md:mt-3 md:ml-2 md:text-regular text-sm">
          <button v-for="medium in mediumFilters" 
              :key="medium" 
              class="rounded-sm py-1 px-2 mb-2"
              :class="medium === activeMediumFilter ? 'bg-slate-50 text-slate-950' : 'bg-transparent hover:bg-slate-50/20'"
              @click="applyMediumFilter(medium)">
              {{ medium }}
          </button>
      </div>

      <!-- Close Button for medium and larger screens -->
      <button class="text-2xl text-slate-50 shadow-lg btn-circle outline outline-red-400/80 mr-3 hover:bg-slate-300/15 hidden sm:block" @click.stop="closePopup">
          <h2 class="font-mono">X</h2>
      </button>

    </div>



      <!-- Display Tag Categories and Tools -->
        <div v-if="isTopBarOpen" class="relative flex flex-col space-y-2 overflow-x-auto text-slate-50 pb-2 pt-2 px-1.5 bg-slate-400/5 mr-2 rounded-md">






            <!-- <div v-for="(tags, category) in categories" :key="category" class="flex space-x-2">
                <span class="font-bold">{{ category }}:</span>
                <span v-for="tag in tags" :key="tag" class="text-sm mr-2">{{ tag }}</span>
            </div>

            <div class="flex flex-col space-y-2 overflow-x-auto">
                <div v-if="uniqueSoftware.length > 0">
                    <span class="font-bold">Software:</span>
                    <span v-for="software in uniqueSoftware" :key="software" class="text-sm mr-2">{{ software }}</span>
                </div>
                <div v-if="uniqueLanguages.length > 0">
                    <span class="font-bold">Languages:</span>
                    <span v-for="language in uniqueLanguages" :key="language" class="text-sm mr-2">{{ language }}</span>
                </div>
                <div v-if="uniqueInstruments.length > 0">
                    <span class="font-bold">Instruments:</span>
                    <span v-for="instrument in uniqueInstruments" :key="instrument" class="text-sm mr-2">{{ instrument }}</span>
                </div>
            </div> -->

        </div>

        <!-- Toggle Button for Top Bar -->
        <div class="absolute hidden top-4 text-center cursor-pointer text-slate-50 h-fit w-fit self-center outline outline-1 outline-slate-50/10 hover:outline-slate-50/50 px-2 mb-1 rounded-full" @click="toggleTopBar">
            <font-awesome-icon :icon="isTopBarOpen ? ['fas', 'angle-up'] : ['fas', 'angle-down']" class="w-8 h-8 pt-1"/>
        </div>
      
    </div>

    <!-- Jobs section -->
    <div class="relative overflow-y-auto flex flex-row flex-wrap md:gap-16 gap-8 justify-center items-center md:ml-1 md:mr-2 pt-8 pb-3 space-y-8" ref="portfolioContainer">
        <JobView
        v-for="(job, index) in filteredJobsByMedium"
        :key="index"
        :job="job"
        :isOpen="openJobIndex === index"
        :index="index"
        @toggle="handleToggle(index)"
      />
    </div>

  </PopupWindow>
</template>
  
<script lang="ts">
import { defineComponent, ref, watch, PropType, nextTick, computed } from 'vue';
import PopupWindow from './PopupWindow.vue';
import JobView from './JobView.vue';
import jobs from '@/jobs.json';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({

  name: 'PortfolioPopup',

  components: 
  {
    PopupWindow,
    JobView,
    FontAwesomeIcon
  },

  props: 
  {
    show: Boolean as PropType<boolean>
  },

  emits: ['update:show'],

  setup(props, { emit }) 
  {

    const openJobIndex = ref(-1);
    const portfolioContainer = ref<HTMLElement | null>(null);

    watch(() => props.show, (newShow) => 
    {
      if (newShow) 
      {
        openJobIndex.value = -1; // Reset open job when the portfolio is shown
        activeMediumFilter.value = 'all'; // Reset medium filter when the portfolio is shown
      }
    });


    const updateShow = (newShow: boolean) => {
      emit('update:show', newShow);
    };


    const isTopBarOpen = ref(false);

    const toggleTopBar = () => {
      isTopBarOpen.value = !isTopBarOpen.value;
    };

    const activeMediumFilter = ref('all');

    const mediumFilters = computed(() => {
      const allMediums = new Set(['all']);

      jobs.forEach(job => {
        job.tags.forEach(tag => {
          if (tag.startsWith('medium:')) {
            // Split combined mediums into individual mediums
            const mediums = tag.substring(7).split(' ')
                              .map(medium => medium.trim()) // Trim each medium
                              .filter(medium => medium); // Filter out empty strings
            mediums.forEach(medium => {
              allMediums.add(medium);
            });
          }
        });
      });

      return Array.from(allMediums);
    });

    const mediumFilterButtons = computed(() => 
    {
        const mediums = mediumFilters.value;
        return Array.from(mediums).map (medium => 
        {
            return medium.replace(/-/g, ' ');
        });
    });

    // Apply medium filter
    const applyMediumFilter = (medium: any) => 
    {
      activeMediumFilter.value = medium;
      handleToggle(-1); // Close all jobs
    };

    const filteredJobsByMedium = computed(() => 
    {
      if (activeMediumFilter.value === 'all') 
      {
        return jobs;
      }

      return jobs.filter(job => 
      {
        // Check if any of the job's medium tags include the selected medium
        return job.tags.some(tag => {
          if (tag.startsWith('medium:')) {
            const mediums = tag.substring(7).split(' ');
            return mediums.includes(activeMediumFilter.value);
          }

          return false;
        });
      });
    });



    const parseDate = (dateStr: string): Date => 
    {
      const parts = dateStr.split('/').map(Number);
      let year, month, day;

      // Check if the date format includes a day
      if (parts.length === 3) 
      {
        [day, month, year] = parts;
      }

      else 
      {
        [month, year] = parts;
        day = 1; // Default day to 1 if not provided
      }

      // Create a date object, adjusting month indexing (JavaScript months are 0-indexed)
      return new Date(year, month - 1, day);
    };


    const sortedJobs = computed(() => 
    {
      return jobs.slice().sort((a: any, b: any) => parseDate(b.date).getTime() - parseDate(a.date).getTime());
    });


// Make sure the categories function doesn't modify the tag format either
const categories = computed(() => {
  const categoryMap = new Map<string, Set<string>>();
  jobs.forEach(job => {
    job.tags.forEach(tagString => {
      const [category, tags] = tagString.split(':').map(t => t.trim());
      if (!categoryMap.has(category)) {
        categoryMap.set(category, new Set());
      }
      tags.split(' ').forEach(tag => {
        categoryMap.get(category)?.add(tag); // Add tags without modifying them
      });
    });
  });
  return Object.fromEntries([...categoryMap].map(([key, value]) => [key, [...value]]));
});


    // Function to extract unique items for each tool category
    const extractUniqueItems = (category: any) => {
      const itemsSet = new Set<string>();
      jobs.forEach(job => {
        // @ts-ignore
        job.tools?.[category]?.forEach(item => itemsSet.add(item));
      });
      return Array.from(itemsSet);
    };

    const uniqueSoftware = computed(() => extractUniqueItems("software"));
    const uniqueLanguages = computed(() => extractUniqueItems("language"));
    const uniqueInstruments = computed(() => extractUniqueItems("instrument"));


    const handleToggle = (index: number) => 
    {
      if (openJobIndex.value === index) 
      {
        openJobIndex.value = -1; // Close the currently open job
      } 
      
      else 
      {
        openJobIndex.value = index; // Open the clicked job
        nextTick(() => scrollToJob(index)); // Scroll to the job after it's opened
      }
    };

    const scrollToJob = (index: number) => 
    {
      const children = portfolioContainer.value?.children;
      const jobElement = children ? children[index] as HTMLElement : null;
      jobElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    };

    const closePopup = () => {
      if (window.history.state?.popupOpened) {
        window.history.back(); // Triggers popstate and closes the popup
      } else {
        emit('update:show', false);
      }
    };

    return { jobs, openJobIndex, updateShow, handleToggle, portfolioContainer, sortedJobs, categories, uniqueSoftware, uniqueLanguages, uniqueInstruments, isTopBarOpen, toggleTopBar, mediumFilters, activeMediumFilter, applyMediumFilter, filteredJobsByMedium, mediumFilterButtons, closePopup  };
  }

});
</script>
