<template>
    <div class="w-fit flex items-center justify-center bg-slate-900/50 backdrop-blur-md">
      <CustomButton additional-classes="py-6 px-8 bg-slate-400/5 rounded-sm hover:bg-slate-200/20" @click="aboutClick">     <h2 class="text-xl font-mono text-slate-100 px-px">about</h2>     </CustomButton>
      <CustomButton additional-classes="py-6 px-8 bg-slate-400/5 rounded-sm hover:bg-slate-200/20" @click="portfolioClick"> <h2 class="text-xl font-mono text-slate-100 px-px">portfolio</h2> </CustomButton>
      <CustomButton additional-classes="py-6 px-8 bg-slate-400/5 rounded-sm hover:bg-slate-200/20" @click="contactClick">   <h2 class="text-xl font-mono text-slate-100 pb-px">contact</h2>   </CustomButton>
    </div>
    <AboutPopup v-model:show="showAbout" />
    <PortfolioPopup v-model:show="showPortfolio" />
    <ContactPopup v-model:show="showContact" />
</template>
  
  <script setup>
  import { ref } from 'vue';
  import CustomButton from './CustomButton.vue';
  import AboutPopup from './AboutPopup.vue';
  import PortfolioPopup from './PortfolioPopup.vue';
  import ContactPopup from './ContactPopup.vue';

  const showAbout = ref(false);
  const showPortfolio = ref(false);
  const showContact = ref(false);

  function aboutClick() {
    showAbout.value = true;
  }

  function portfolioClick() {
    showPortfolio.value = true;
  }

  function contactClick() {
    showContact.value = true;
  }

  // export default {
  //   name: 'NavigationBar',
  //   components: {
  //     CustomButton,
  //     AboutPopup
  //   }
  // }

  </script>
  