<template>
    <a :href="link" target="_blank" class="bg-slate-950/90 hover:bg-slate-700/30 relative p-1.5 h-14 aspect-square" v-if="socialIcon">
      <font-awesome-icon :icon="socialIcon" class="w-full h-full text-slate-50"/>
    </a>
  </template>
  
<script setup>

import { computed, defineProps } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faYoutube, faItchIo, faSoundcloud, faBandcamp, faSpotify, faInstagram, faFacebook, faLinkedin, faGithub, faSteam } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

// import link from font awesome


const props = defineProps({
    link: {
    type: String,
    required: true
    }
});

const socialIcon = computed(() => {

    if (props.link.includes('youtube.com') || props.link.includes('youtu.be')) return faYoutube;
    if (props.link.includes('itch.io')) return faItchIo;
    if (props.link.includes('soundcloud.com')) return faSoundcloud;
    if (props.link.includes('bandcamp.com')) return faBandcamp;
    if (props.link.includes('spotify.com')) return faSpotify;
    if (props.link.includes('instagram.com')) return faInstagram;
    if (props.link.includes('facebook.com')) return faFacebook;
    if (props.link.includes('linkedin.com')) return faLinkedin;
    if (props.link.includes('github.com')) return faGithub;
    if (props.link.includes('steampowered.com')) return faSteam;
    
    // Default icon 
    return faLink;
});
</script>