<template>
  <div class="relative text-slate-50" :class="isOpen ? 'w-screen h-2/3 bg-slate-50/10 px-4 py-6 rounded-md' : 'w-1/3 md:w-48 aspect-square'">

    <!-- Closed View: Image and Title -->
    <div class="w-full relative cursor-pointer group" @click="toggleOpen" :class="isOpen ? 'hidden' : 'block'">

        <img :src="job.image" class="object-cover w-full aspect-square" />

        <!-- Title overlay at the bottom of the image -->
        <h3 class="absolute bottom-0 w-full text-center text-slate-50 bg-slate-950/70 py-1 font-normal md:hidden group-hover:block md:text-regular text-xs">
          {{ job.title }}
        </h3>

    </div>

    <!-- Open View: Full Details -->
    <div v-if="isOpen" class="w-full h-full flex md:flex-row flex-col ml-1">

      <img :src="job.image" class="object-cover w-full pr-2 md:pr-0 pb-5 md:pb-0 md:w-2/5 md:h-2/5 aspect-square rounded-sm shadow-xl" />

      <div class="flex-1 md:mx-8 mx-4">

        <!-- TITLE -->
        <div class="w-fit">

          <h3 class="text-4xl font-bold font-mono w-fit pb-1">{{ job.title }}</h3>
          <div class="h-0.5 mx-0 w-auto bg-slate-100/70 rounded-3xl"></div>

        </div>

        <!-- ORGANIZATION -->
        <div class="flex flex-col mt-3 mb-4 space-x-4 items-start justify-center">

          <p class="font-semibold text-lg">~ {{ job.organization }} ~</p>
          <p class="relative text-sm font-mono italic md:mt-2 md:-left-4">{{ formatDate(job.date) }}</p>

        </div>

        <!-- LINKS -->
        <div class="w-full">

          <div class="h-px mx-0 w-full bg-slate-950/50"></div>
          <div class="flex flex-row my-4 space-x-4">
            <SocialLink v-for="(link, index) in job.links" :key="index" :link="link" class="" />
          </div>
          <div class="h-px mx-0 w-full bg-slate-950/50"></div>

        </div>


        <div class="flex flex-row flex-wrap space-x-2">

          <!-- Display grouped tags -->
          <div v-if="groupedTags" class="flex flex-row flex-wrap space-x-2">


            <div v-for="(tags, category) in groupedTags" :key="category" class="flex flex-row items-center justify-start w-fit text-gray-800 bg-gray-200 rounded px-2 my-1 pb-0.5">
              <h4 class="italic text-sm">{{ category }}:</h4>
              <ul class="flex flex-row font-mono text-xs pt-1 flex-wrap">
                <li v-for="(tag, index) in tags" :key="tag" class="pl-2">
                  {{ tag }}<span v-if="index < tags.length - 1">,</span>
                </li>
              </ul>
            </div>

          </div>

          <!-- Display tools for the job -->
          <div v-if="job.tools" class="text-gray-800 flex flex-row flex-wrap space-x-2 my-0.5">

            <div v-if="job.tools.software && job.tools.software.length > 0" class="flex flex-row w-fit items-center justify-start bg-gray-200 rounded px-2 my-0.5 pb-0.5">
              <h4 class="italic text-sm">software: </h4>
              <ul class="flex flex-row font-mono text-xs pt-1 flex-wrap">
                <li v-for="(software, index) in job.tools.software" :key="software" class="pl-2">{{ software }}<span v-if="index < job.tools.software.length - 1">,</span></li>
              </ul>
            </div>

            <div v-if="job.tools.language && job.tools.language.length > 0" class="flex flex-row w-fit items-center justify-start bg-gray-200 rounded px-2 my-0.5 pb-0.5 mr-2">
              <h4 class="italic text-sm">languages:</h4>
              <ul class="flex flex-row font-mono text-xs pt-1 flex-wrap">
                <li v-for="(language, index) in job.tools.language" :key="language" class="pl-2">{{ language }}<span v-if="index < job.tools.language.length -1">,</span></li>
              </ul>
            </div>

            <div v-if="job.tools.instrument && job.tools.instrument.length > 0" class="flex flex-row w-fit items-center justify-start bg-gray-200 rounded px-2 my-0.5 pb-0.5">
              <h4 class="italic text-sm">instruments:</h4>
              <ul class="flex flex-row font-mono text-xs pt-1 flex-wrap">
                <li v-for="(instrument, index) in job.tools.instrument" :key="instrument" class="pl-2">{{ instrument }}<span v-if="index < job.tools.instrument.length - 1">,</span></li>
              </ul>
            </div>

          </div>

        </div>




        <p class="text-lg font-thin py-4">{{ job.writeUp }}</p>



      </div>

    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import SocialLink from './SocialLink.vue';

export default defineComponent({

  name: 'JobView',

  components: {
    SocialLink
  },

  props: {
    job: {
      type: Object as PropType<{
        title: string;
        organization: string;
        image: string;
        links: string[];
        writeUp: string;
        tags: string[];
        tools: string[];
        date: string;
      }>,
      required: true
    },
    isOpen: Boolean,
    index: Number
  },

  setup(props, { emit }) 
  {
    const toggleOpen = () => 
    {
      emit('toggle', props.index);
    };

    const filteredTags = computed(() => {
      return props.job.tags.filter(tag => !tag.startsWith('medium:'));
    });

    const groupedTags = computed(() => {
      const groups: Record<string, string[]> = {};
      filteredTags.value.forEach(tag => {
        const [category, content] = tag.split(':');
        const items = content.trim().split(' ');
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(...items);
      });
      return groups;
    });

    return { toggleOpen, filteredTags, groupedTags };
  },

  methods: {

    formatDate(dateStr: string): string {
      if (!dateStr) return '';

      const parts = dateStr.split('/');
      let year, month, day;

      if (parts.length === 3) {
        // Format: dd/mm/yyyy
        [day, month, year] = parts;
      } else {
        // Format: mm/yyyy
        [month, year] = parts;
        day = null;
      }

      const date = new Date(`${month}/${day || '1'}/${year}`);
      const monthName = date.toLocaleString('default', { month: 'long' });

      if (day) {
        return `${monthName.toLowerCase()} ${day}, ${year}`;
      }
      return `${monthName.toLowerCase()} ${year}`;
    }

  }

});
</script>
