import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "fixed z-30 inset-0 bg-black/30 backdrop-blur-md flex justify-center items-center md:p-12 hover:cursor-pointer",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)), ["self"]))
      }, [
        _createElementVNode("div", {
          class: "bg-slate-950/80 w-full h-full md:p-6 p-5 rounded-lg shadow-lg shadow-slate-900/70 relative max-h-full overflow-auto cursor-default",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "default", { class: "backdrop-blur-xl" })
        ])
      ]))
    : _createCommentVNode("", true)
}