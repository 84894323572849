<template>
    <div class="fixed inset-0 bg-slate-900">

      <!-- Blurred background -->
      <div class="absolute h-fit inset-0 bg-gradient-to-r from-blue-950/90 via-slate-800 to-blue-850/90 backdrop-blur-xl">
        <div v-for="circle in circles" :key="circle.id" :class="circle.classes" :style="circle.style"></div>
      </div>

      <!-- Content -->
      <div class="relative min-h-screen flex flex-col items-center justify-start">
        <slot></slot> <!-- Slot for nested content -->
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'AnimatedBackground',
    data() {
      return {
        circles: Array.from({ length: 10 }, (_, id) => ({
          id,
          classes: 'absolute rounded-full opacity-75 z-30',
          style: {
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: 'float 10s ease-in-out infinite',
            backgroundColor: this.randomColor(),
            width: `${Math.random() * 100 + 50}px`, // random size between 50px to 150px
            height: 'width', // to make it a circle
          },
        })),
      };
    },
    methods: {
      randomColor() {
        const colors = ['bg-blue-500', 'bg-red-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500'];
        return colors[Math.floor(Math.random() * colors.length)];
      },
    },
  });
  </script>

<style>
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
</style>
  