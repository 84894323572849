<template>
    <div class="md:px-20 px-2 min-w-full md:min-w-fit py-3 md:mt-8 backdrop-blur-xl bg-slate-800/80 shadow-lg outline outline-1 rounded-sm outline-slate-100/40
                md:max-w-full">

      <!-- mx-auto centers the card, max-w-3/5 and md:max-w-full control the width -->
      <p class="font-mono md:text-sm text-xs italic text-secondary text-center md:pt-1 pb-3">
        creativity engineer (noun):
      </p>

      <div class="border-t border-slate-400/40"></div> <!-- Top line --> 

      <h1 class="md:text-6xl text-5xl font-bold text-center text-primary pt-3 pb-5 shadow-sm stroke-2 stroke-slate-900 backdrop-blur-2xl bg-slate-950/5 rounded-sm md:px-4">
        John Janigan-Mills
      </h1>

    <div class="border-b border-slate-400/40"></div> <!-- Bottom line -->

      <p class="text-secondary text-center font-mono md:text-xl text-sm pt-3 md:pb-2 md:font-light font-normal shadow-sm stroke-2 stroke-slate-50">
        musician . composer . audio engineer . programmer
      </p>
    </div>
</template>
  
  <script lang="ts">
  export default {
    name: 'TitleHeader'
  }
  </script>
  